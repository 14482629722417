








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SecondPartnerInfo extends Vue {
  @Prop() private msg!: string;

  created() {
    this.$store.dispatch('auth/getSecondPartner').then(res => {
      console.log(res);
    });
  }

}
